import(/* webpackMode: "eager", webpackExports: ["Box"] */ "/home/vsts/work/1/s/node_modules/@preventx/oxygen/src/components/Container/Box/Box.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ToastContainer","toast","info","success","warning","error"] */ "/home/vsts/work/1/s/node_modules/react-toastify/dist/react-toastify.esm.mjs");
;
import(/* webpackMode: "eager" */ "/home/vsts/work/1/s/node_modules/react-toastify/dist/ReactToastify.css");
;
import(/* webpackMode: "eager" */ "/home/vsts/work/1/s/node_modules/@preventx/carbon/carbon-min.css");
;
import(/* webpackMode: "eager" */ "/home/vsts/work/1/s/node_modules/@preventx/carbon/themes/diagnode.css");
;
import(/* webpackMode: "eager" */ "/home/vsts/work/1/s/app/index.css");
;
import(/* webpackMode: "eager", webpackExports: ["HeadingManager"] */ "/home/vsts/work/1/s/src/components/HeadingManager.tsx");
